import React, { useMemo, useState } from "react";
import "../../custom.css";
import { ProfilePrefValues, useAuth } from "../../authentication/useAuth";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Chip, CircularProgress, Stack, TextField } from "@mui/material";
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useForm } from "react-hook-form";
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';
import GppMaybeTwoToneIcon from '@mui/icons-material/GppMaybeTwoTone';

export default function Preferences() {

    const { user, sendEmailVerification, savePreferences } = useAuth();

    const [progress, setProgress] = useState(false);
    const [emailPrefs, setEmailPrefs] = useState({ email: user.email, name: user.name });
    const { register, handleSubmit } = useForm<ProfilePrefValues>();
    const verified = useMemo(() => {
        return user?.isEmailVerified ?? false;
    }, [user]);

    const [chipText, setChipText] = useState("Unverified");

    const handleSendEmail = async () => {
        await sendEmailVerification(emailPrefs.email);
    };

    const onPrefsSubmit = async () => {
        setProgress(true);
        await savePreferences(emailPrefs).then(r => {
            
        }).finally(() => {
            setProgress(false);
        });
    };

    const contents =
        <form onSubmit={handleSubmit(onPrefsSubmit)}>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Profile</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={4}>
                        <TextField {...register("name")} value={emailPrefs.name} required id="outlined-required-username" label="Name"
                            onChange={(e) => setEmailPrefs({ ...emailPrefs, name: e.target.value })} />
                        <Stack direction="row" spacing={2}>
                            <TextField sx={{ flex: 1 }} {...register("email")} value={emailPrefs.email} required id="outlined-required-username" label="Email"
                                onChange={(e) => setEmailPrefs({ ...emailPrefs, email: e.target.value })} disabled />
                            {verified &&
                                <Chip sx={{ width: "200px", alignSelf: "center" }} icon={<VerifiedUserTwoToneIcon color="success" />}
                                    label="Verified" variant="outlined" />
                            }
                            {!verified &&
                                <Chip sx={{ width: "200px", alignSelf: "center" }} icon={<GppMaybeTwoToneIcon />}
                                    label={chipText} variant="outlined"
                                    onMouseOver={() => { setChipText("Send verification email"); }}
                                    onMouseOut={() => { setChipText("Unverified"); }}
                                    onClick={handleSendEmail}
                                    clickable />
                            }
                        </Stack>
                    </Stack>
                </AccordionDetails>
                <AccordionActions>
                    <Button type="submit" variant="contained" startIcon={
                        progress &&
                        <CircularProgress sx={{ padding: "0" }} size="1em" />}
                    >Save</Button>
                </AccordionActions>
            </Accordion>
        </form>
    return (
        <div style={{ flex: 'auto' }}>
            <div>
                <h1 id="tableLabel">Preferences</h1>
                <Box sx={{ width: "700px", marginLeft: "auto", marginRight: "auto" }}>
                    {contents}
                </Box>
            </div>
        </div>
    );
}