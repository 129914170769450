import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Alert, AlertColor, Stack } from "@mui/material";
import { useAuth } from "../authentication/useAuth";
import "../custom.css";
import { useSearchParams } from "react-router-dom";

export default function VerifyEmail() {

    const [searchParams] = useSearchParams();
    const { verifyEmail } = useAuth();
    const [showAlert, setShowAlert] = useState({ show: false, progress: true, message: "", severity: "info" as AlertColor });

    const verificationCode = useMemo(() => {
        const code = searchParams.get("verificationCode");
        setShowAlert({ show: true, progress: true, message: "Verifying...", severity: "info" });
        return code;
    }, [searchParams]);

    useEffect(() => {
        verifyEmail(verificationCode).then(() => { 
            setShowAlert({ show: true, progress: false, message: "All set!", severity: "success" });
        }).catch(() => {
            setShowAlert({ show: true, progress: false, message: "There was an error, please try again", severity: "error" });
        });
    }, []);

    return (
        <div className="verifyEmail" style={{ display: "flex", flex: "1", justifyContent: "center" }}>
            <Box sx={{ width: "300px" }}>
                <Stack spacing={2}>
                    {showAlert?.show &&
                        <Alert severity={showAlert.severity} iconMapping={{ info: <CircularProgress sx={{ padding: "0" }} size="1em" /> }}>
                            {showAlert.message}
                        </Alert>
                    }
                </Stack>
            </Box>
        </div>
    );
}