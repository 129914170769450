import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { User } from "./User";

export type FormValues = {
  email: string
  password: string,
  isPersistent: boolean
}

export type ProfilePrefValues = {
  name: string;
  email: string;
}

const USER_KEY = "user";

export const AuthContext = createContext({
  user: null as User,
  setUser: (user: User) => { },
  login: (form: FormValues) => { return Promise.resolve(); },
  impersonate: (id: string) => { return Promise.resolve(); },
  logout: () => { },
  forgotPassword: (email: string) => { return Promise.resolve(); },
  resetPassword: (resetCode: string, password: string) => { return Promise.resolve(); },
  sendEmailVerification: (email: string) => { return Promise.resolve(); },
  verifyEmail: (code: string) => { return Promise.resolve(); },
  savePreferences: (prefs: ProfilePrefValues) => { return Promise.resolve({} as User); }
});

export const useAuth = () => useContext(AuthContext);

export const Auth = ({ children }: { children: ReactNode }) => {

  const auth = useAuth();
  const [user, setUser] = useState<User>(() => {
    return JSON.parse(localStorage.getItem("user"));
  });

  const storeUser = (user: User) => {
    setUser(user);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  };

  useEffect(() => {
    const checkUser = async () => {
      await fetch("/api/user/current", {
        credentials: 'include'
      })
        .then(async (result) => {
          const currentUser = await result?.json();
          storeUser(currentUser);
        })
        .catch(() => storeUser(null));
    }

    checkUser()
  }, [auth]);

  const value = {
    user: user,

    setUser: setUser,

    login: async (form: FormValues) => {
      const data = new FormData()
      data.append("email", form.email);
      data.append("password", form.password);
      data.append("isPersistent", form.isPersistent.toString());

      const response = await fetch("/api/user/signin", {
        method: "POST",
        body: data,
        credentials: "include"
      });

      return await value.storeUserFromResponse(response);
    },

    impersonate: async (id: string) => {
      const response = await fetch(`/api/user/${id}/impersonate`, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        credentials: "include"
      });

      return await value.storeUserFromResponse(response);
    },

    storeUserFromResponse: async (response: Response) => {
      try {
        const result = await response?.json();
        if (response.ok) {
          storeUser(result);
          return Promise.resolve(result);
        }
        return Promise.reject(result);
      }
      catch {
        storeUser(null);
      }
      return Promise.reject();
    },

    logout: async () => {
      await fetch("/api/user/logout", {
        method: "POST"
      });
      storeUser(null);
    },

    forgotPassword: async (email: string) => {

      await fetch(`/api/user/forgotpassword?email=${email}`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
      });
    },

    resetPassword: async (resetCode: string, password: string) => {
      await fetch(`/api/user/passwordreset`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          resetCode: resetCode,
          password: password
        })
      });

    },

    sendEmailVerification: async (email: string) => {
      await fetch(`/api/user/sendemailverification?email=${email}`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
      });
    },

    verifyEmail: async (code: string) => {
      await fetch(`/api/user/verifyemail`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          verificationCode: code
        })
      }).then((r) => {
        if (r.status === 200) return Promise.resolve(r);
        return Promise.reject(r);
      });
    },

    savePreferences: async (prefs: ProfilePrefValues): Promise<User> => {
      return await fetch(`/api/user/savepreferences`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(prefs)
      }).then(async (r) => {
        if (r.status === 200) {
          return await value.storeUserFromResponse(r);
        }
        return Promise.reject(null);
      });
    }
  }
  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>
};