import dayjs from "dayjs";
import { DayObject } from "./Calendar/Calendar";
import { PlanState, Prediction, PlanDayDictionary, PredictionResponse } from "./Planner";

export const PlannerServices =
{
    getPlans: async () => {
        const planResponse = await fetch("/api/plan/plans",
            {
                method: "GET",
                credentials: "include"
            });
        const allPlans = await planResponse.text();

        let userPlan = JSON.parse(allPlans, Helpers.jsonParseReviver) as PlanState[];
        return userPlan[0];
    },

    createPrediction: async (planId: string, day: DayObject, prediction: Prediction) => {
        const response = await fetch(`/api/plan/${planId}/day/${day.dateString}/predictions`,
            {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify(prediction)
            });

        // TODO: Response is now a PredictionResponseV1 wrapper
        return await Helpers.convertResponse(response);
    },

    updatePrediction: async (planId: string, day: DayObject, prediction: Prediction, moveDay?: DayObject) => {
        const id = prediction.parentId ?? prediction.id;
        let url = `/api/plan/${planId}/day/${day.dateString}/prediction/${id}`;
        if (moveDay && moveDay.dateString !== day.dateString) {
            url = `/api/plan/${planId}/day/${day.dateString}/to/${moveDay.dateString}/prediction/${id}`;
        }

        const response = await fetch(url,
            {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                method: "PUT",
                credentials: "include",
                body: JSON.stringify(prediction)
            });
        if (response.status / 2 !== 0) {
            // Handle error
        }

        // TODO: Response is now a PredictionResponseV1 wrapper
        return await Helpers.convertResponse(response);
    },

    updateDayTotal: async (planId: string, day: DayObject, amount: number) => {
        const response = await fetch(`/api/plan/${planId}/day/${day.dateString}`,
            {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                method: "PUT",
                credentials: "include",
                body: JSON.stringify({ total: amount })
            });
        if (response.status / 2 !== 0) {
            // Handle error
        }

        return await Helpers.convertResponse(response);
    },

    deletePrediction: async (planId: string, day: DayObject, prediction: Prediction) => {
        const id = prediction.parentId ?? prediction.id;
        const response = await fetch(`/api/plan/${planId}/day/${day.dateString}/prediction/${id}`,
            {
                method: "DELETE",
                credentials: "include"
            });
        if (response.status / 2 !== 0) {
            // Handle error
        }

        return await Helpers.convertResponse(response);
    },

    restorePlan: async (state: PlanState) => {
        const response = await fetch(`/api/plan/${state.id}`,
            {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify(state)
            });
        if (response.status / 2 !== 0) {
            // Handle error
        }
    }
}

const Helpers = {

    convertResponse: async (response: Response) => {
        const json = await response.text();
        if (!json || json?.length === 0) {
            return {} as PlanDayDictionary;
        }

        // We've got a list of PlanDay so convert it to a dictionary
        let predictionResponse = JSON.parse(json, Helpers.jsonParseReviver) as PredictionResponse;
        const days = predictionResponse.days;

        if (days) {
            const dict = Object.assign({}, ...days.map(({
                day,
                ...rest
            }) => ({
                [day.format("YYYY-MM-DD")]: rest
            }))) as PlanDayDictionary;
            return dict;
        }

        return {} as PlanDayDictionary;
    },

    jsonParseReviver: (key: any, value: any) => {
        const dateKeys = ["day", "until"];
        if (dateKeys.includes(key)) {
            const d = dayjs(value, ["DD-MM-YYYY", "YYYY-MM-DD"]);
            return d;
        }
        if (key === "on") {
            return dayjs(value);
        }
        if (key === "deferred") {
            return (value as string[]).map(d => dayjs(d, ["DD-MM-YYYY", "YYYY-MM-DD"]))
        }
        return value;
    }
}