import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Stack, TextField, FormGroup, Button, Alert, AlertColor } from "@mui/material";
import { useAuth, FormValues } from "../authentication/useAuth";
import "../custom.css";

export default function ForgottenPassword() {

    const [email, setEmail] = useState("");
    const [showAlert, setShowAlert] = useState({ show: false, message: "" , severity: "warning" as AlertColor});

    const { forgotPassword } = useAuth();
    const { register, handleSubmit } = useForm<FormValues>();

    const onSubmit = async () => {
        setShowAlert({ show: false, message: "", severity: "warning" });
        await forgotPassword(email).then(
            () => {
                setShowAlert({ show: true, message: "Check your email and follow the instructions.", severity: "success" });
            },
            (rejected) => {
                setShowAlert({ show: true, message: rejected?.error ?? "", severity: "warning" });
            });
    };

    return (
        <div className="login">
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup sx={{ mt: 2, width: 400 }}>
                    <Stack spacing={2}>
                        <TextField {...register("email")} required id="outlined-required-username" label="Email"
                            onChange={(e) => setEmail(e.target.value)} />
                        <Button type="submit">Send reset email</Button>
                        {showAlert?.show &&
                            <Alert severity={showAlert.severity}>
                                {showAlert.message}
                            </Alert>
                        }
                    </Stack>
                </FormGroup>
            </form>
        </div>
    );
}