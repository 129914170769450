import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Stack, TextField, FormGroup, Button, Alert, Link, CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, FormValues } from "../authentication/useAuth";
import "../custom.css";

export default function Login() {

    const location = useLocation();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showAlert, setShowAlert] = useState({ show: false, progress: false, message: "" });

    const { login } = useAuth();
    const { register, handleSubmit } = useForm<FormValues>();

    const onSubmit = async () => {
        setShowAlert({ show: false, progress: true, message: "" });

        await login({ email, password, isPersistent: true }).then(
            () => {
                const { from } = location.state || { from: { pathname: "/" } };
                navigate(from, { replace: true });
            },
            (rejected) => {
                setShowAlert({ show: true, progress: false, message: rejected?.error ?? "" });
            });
    };

    return (
        <div className="login">
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup sx={{ mt: 2, width: 400 }}>
                    <Stack spacing={2}>
                        <TextField {...register("email")} required id="outlined-required-username" label="Email"
                            onChange={(e) => setEmail(e.target.value)} />
                        <TextField  {...register("password")} required id="outlined-required-password" label="Password" type="password"
                            onChange={(e) => setPassword(e.target.value)}></TextField>
                        <Button type="submit" startIcon={
                            showAlert.progress && 
                            <CircularProgress sx={{ padding: "0" }} size="1em" />}
                        >
                            Login
                        </Button>
                        <Link href="/forgottenpassword" variant="caption">I've forgotten my password</Link>
                        {showAlert?.show &&
                            <Alert severity="error">
                                {showAlert.message}
                            </Alert>
                        }
                    </Stack>
                </FormGroup>
            </form>
        </div>
    );
}