import React from "react";
import { Home } from "./components/Home";
import Layout from "./components/Layout";
import Planner from "./components/Planner";
import Login from "./components/Login";
import Preferences from "./components/preferences/Preferences";
import Administration from "./components/administration/Administration";
import ProtectedRoute from "./components/ProtectedRoute";
import Users from "./components/administration/Users";
import AdministrationShell from "./components/administration/AdministrationShell";
import ForgottenPassword from "./components/ForgottenPassword";
import ResetPassword from "./components/ResetPassword";
import VerifyEmail from "./components/VerifyEmail";

const AppRoutes = [
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/forgottenpassword',
        element: <ForgottenPassword />
      },
      {
        path: '/resetpassword',
        element: <ResetPassword />
      },
      {
        path: '/verifyemail',
        element: <VerifyEmail />
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: '/planner',
            element: <Planner />
          },
          {
            path: '/preferences',
            element: <Preferences />
          },
          {
            path: '/administration',
            element: <AdministrationShell />,
            children: [
              {
                path: '/administration',
                element: <Administration />,
                index: true
              },
              {
                path: '/administration/users',
                element: <Users />
              }
            ]
          }
        ]
      }
    ]
  }
];

export default AppRoutes;
