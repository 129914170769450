import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, FormGroup, AlertColor, Box, IconButton, InputAdornment, Stack, Button, Alert } from "@mui/material";
import { useAuth, FormValues } from "../authentication/useAuth";
import "../custom.css";
import { useSearchParams } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function ResetPassword() {

    const [searchParams] = useSearchParams();
    const [showAlert, setShowAlert] = useState({ show: false, message: "", severity: "warning" as AlertColor });

    const { resetPassword } = useAuth();
    const { register, handleSubmit } = useForm<FormValues>();
    const [passwords, setPasswords] = useState({ password1: "", password2: "" });
    const [showPassword, setShowPassword] = useState(false);

    const hasPasswordError = useMemo(
        () => passwords.password1 !== passwords.password2, [passwords]);
    const getPasswordError = useMemo(
        () => ((passwords.password1 !== passwords.password2) && passwords.password2.length > 0
            ? "Passwords do not match!"
            : ""),
        [passwords]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const resetCode = useMemo(() => {
        const code = searchParams.get("resetCode");
        return code;
    }, [searchParams]);

    const onSubmit = async () => {
        setShowAlert({ show: false, message: "", severity: "warning" });
        await resetPassword(resetCode, passwords.password1).then(
            () => {
                setShowAlert({ show: true, message: "Now login...", severity: "success" });
            },
            (rejected) => {
                setShowAlert({ show: true, message: rejected?.error ?? "", severity: "warning" });
            });
    };

    return (
        <div className="login">
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup sx={{ mt: 2, width: 400 }} onSubmit={() => onSubmit()}>
                    <Stack spacing={2}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <TextField
                                style={{ flex: "1" }}
                                autoFocus
                                required
                                margin="dense"
                                id="password"
                                name="password1"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                value={passwords.password1}
                                onChange={(event) => setPasswords({ ...passwords, password1: event.target.value })}
                                variant="standard"
                                sx={{ marginRight: "8px" }} />
                            <TextField
                                style={{ flex: "1" }
                                }
                                required
                                margin="dense"
                                id="password2"
                                name="password2"
                                label="Password Verification"
                                type={showPassword ? 'text' : 'password'}
                                value={passwords.password2}
                                onChange={(event) => {
                                    setPasswords({ ...passwords, password2: event.target.value });
                                }}
                                variant="standard"
                                error={hasPasswordError}
                                helperText={getPasswordError}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} />
                        </Box>
                        <Button type="submit">Reset</Button>
                        {showAlert?.show &&
                            <Alert severity={showAlert.severity}>
                                {showAlert.message}
                            </Alert>
                        }
                    </Stack>
                </FormGroup>
            </form>
        </div>
    );
}